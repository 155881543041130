export interface Insurance {
  id?: string;
  name: string;
  description?: string;
  source?: string;
  startDate: string;
  startsOn: string;
  endDate: string;
  endsOn: string;
  frequency: string;
  joint: boolean;
  payout: number;
  payoutCurrency: string;
}

export interface MortgageInsurance extends Insurance {
  mortgageId: string;
}
