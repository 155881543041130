// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.coin-container {
  touch-action: none;
  -ms-touch-action: none;
  visibility: visible !important;
  transition: all 0.2s ease-out;
}
.coin-container.disabled {
  opacity: 0.3;
}

.coin-label {
  max-width: 75px;
}

.disabled .coin {
  cursor: default;
}

.info-panel {
  margin: 0 20px 20px;
}

.hide {
  display: none;
}

#dropzone_expense_2 .coin,
#dropzone_expense_1 .coin,
#dropzone_income_1 .coin,
#dropzone_income_2 .coin {
  display: none !important;
}

.mat-icon {
  display: inline;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}

.button-cancel {
  display: flex;
  position: relative;
  top: 10px;
  align-self: flex-start;
}

.coin-icon {
  display: flex;
  position: relative;
  top: 0px;
  align-self: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/income/income.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;;;;EAIE,wBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,QAAA;EACA,sBAAA;AACF","sourcesContent":[".mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.coin-container {\n  touch-action: none;\n  -ms-touch-action: none;\n  visibility: visible !important;\n  transition: all 0.2s ease-out;\n  &.disabled {\n    opacity: 0.3;\n  }\n}\n\n.coin-label {\n  max-width: 75px;\n}\n\n.disabled .coin {\n  cursor: default;\n}\n\n.info-panel {\n  margin: 0 20px 20px;\n}\n\n.hide {\n  display: none;\n}\n\n#dropzone_expense_2 .coin,\n#dropzone_expense_1 .coin,\n#dropzone_income_1 .coin,\n#dropzone_income_2 .coin {\n  display: none !important;\n}\n\n.mat-icon {\n  display: inline;\n}\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n  &.visible {\n    visibility: visible;\n  }\n}\n\n.button-cancel {\n  display: flex;\n  position: relative;\n  top: 10px;\n  align-self: flex-start;\n}\n\n.coin-icon {\n  display: flex;\n  position: relative;\n  top: 0px;\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
