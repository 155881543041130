export interface FinancialPortfolio {
  id?: string;
  name: string;
  profile: string;
  source?: string;
  wrapper: string;
  currency: string;
  value: number;
  description?: string;
  fees: number;
}
