// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  min-height: 300px;
}
@media screen and (max-width: 599px) {
  .panel {
    min-height: auto;
  }
}

.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.mat-form-row-pinned {
  background: #f7f8f9;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#dropzone_2 .coin,
#dropzone_1 .coin {
  display: none !important;
}

.disabled .coin {
  cursor: default;
}

.coin-container-chevron {
  position: relative;
}
.coin-container-chevron .menu-chevron {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 30px;
  width: auto;
  height: auto;
}

.coin-container {
  touch-action: none;
  -ms-touch-action: none;
  visibility: visible !important;
  transition: all 0.2s ease-out;
}
.coin-container.disabled {
  opacity: 0.3;
}

.mat-icon {
  display: inline;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/family/family.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EAFF;IAGI,gBAAA;EAGF;AACF;;AAAA;EACE,oBAAA;AAGF;;AAAA;EACE,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AAGF;;AAAA;;EAEE,wBAAA;AAGF;;AAAA;EACE,eAAA;AAGF;;AAAA;EACE,kBAAA;AAGF;AADE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AAGJ;;AACA;EACE,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;AAEF;AADE;EACE,YAAA;AAGJ;;AACA;EACE,eAAA;AAEF;;AACA;EACE,kBAAA;AAEF;AADE;EACE,mBAAA;AAGJ","sourcesContent":[".panel {\n  min-height: 300px;\n  @media screen and (max-width: 599px) {\n    min-height: auto;\n  }\n}\n\n.mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.mat-form-row-pinned {\n  background: #f7f8f9;\n  border-radius: 4px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n\n#dropzone_2 .coin,\n#dropzone_1 .coin {\n  display: none !important;\n}\n\n.disabled .coin {\n  cursor: default;\n}\n\n.coin-container-chevron {\n  position: relative;\n\n  .menu-chevron {\n    font-size: 12px;\n    position: absolute;\n    right: 10px;\n    top: 30px;\n    width: auto;\n    height: auto;\n  }\n}\n\n.coin-container {\n  touch-action: none;\n  -ms-touch-action: none;\n  visibility: visible !important;\n  transition: all 0.2s ease-out;\n  &.disabled {\n    opacity: 0.3;\n  }\n}\n\n.mat-icon {\n  display: inline;\n}\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n  &.visible {\n    visibility: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
