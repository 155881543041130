import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { DragulaService } from 'ng2-dragula';
import { Subscription, forkJoin, iif, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { AppFeatureType } from 'src/app/feature';
import { FeatureService } from 'src/app/feature/feature.service';
import {
  IMoneyhubTransactionData,
  Insurance,
  InvestmentProperty,
  MoneyhubUser,
  Person,
  Property,
  TransactionItem,
} from 'src/app/model';
import { AnalyticsEvent, AnalyticsLabel } from 'src/app/model/analytics';
import {
  BaseGrowthRate,
  DateRangeType,
  Frequency,
  UnearnedIncomeType,
} from 'src/app/model/enums';
import {
  LivingExpense,
  LivingExpenseBuilder,
} from 'src/app/model/household/expense';
import {
  RentExpense,
  RentExpenseBuilder,
} from 'src/app/model/household/expense/rent.expense';
import {
  EarnedIncome,
  EarnedIncomeBuilder,
  RentIncome,
  RentIncomeBuilder,
  UnearnedIncome,
  UnearnedIncomeBuilder,
} from 'src/app/model/household/income';
import {
  AnalyticsService,
  DisabilityInsuranceService,
  FinancialAssetsService,
  PropertyService,
} from 'src/app/services';
import { AppConfigService } from 'src/app/services/app.config.service';
import {
  LivingExpenseService,
  RentExpenseService,
} from 'src/app/services/expense.service';
import {
  EarnedIncomeService,
  RentIncomeService,
  UnearnedIncomeService,
} from 'src/app/services/income.service';
import { MoneyhubService } from 'src/app/services/moneyhub.service';
import { PersonsService } from 'src/app/services/persons.service';
import { DateUtils } from 'src/app/utils';
import { WizardService } from '../../../services';
import { UserPropertyService } from '../../../services/user.property.service';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent implements OnInit, OnDestroy {
  private _navigation: any;

  private _scenarioId: string;
  private _primary: Person;
  private _partner: Person;

  private _primaryEarnedIncomes: Array<EarnedIncome>;
  private _partnerEarnedIncomes: Array<EarnedIncome>;
  private _unearnedIncomes: Array<UnearnedIncome>;
  private _rentIncomes: Array<RentIncome>;
  private _livingExpenses: Array<LivingExpense>;
  private _rentExpenses: Array<RentExpense>;
  private _linkedIncomes: Array<EarnedIncome>;
  private _linkedUnearnedIncomes: Array<UnearnedIncome>;
  private _linkedExpenses: Array<LivingExpense>;
  private _linkedRentExpenses: Array<LivingExpense>;

  private _investmentProperties: Array<InvestmentProperty>;
  private _rentalIncomesPerAnnum: number[] = [];

  private _primaryDisabilityInsurance: Array<Insurance>;
  private _partnerDisabilityInsurance: Array<Insurance>;

  private _subs: Subscription[] = [];

  private _onDragEnd: Function;
  private _dragParam: any;
  private _dragCategory: string;
  private _draggingCategory: string;
  private _loading: boolean = false;
  private _showInputForId: string;
  private _locale: string;

  private _expenseTmp;
  private _basicExpenseTmp;
  private _rentExpenseTmp;
  private _incomeTmp;
  private _incomeUnearnedTmp;
  private _scrollableIncome = true;
  private _scrollableExpense = true;
  public breakpoint: number;
  public totalIncomes = 4;
  public totalExpenses = 5;
  public breakpointIncomes;
  public breakpointExpenses;
  public linkedExpensesList: TransactionItem[] = [];
  public linkedIncomesList: TransactionItem[] = [];

  public linkedEarnedIncomesList: IMoneyhubTransactionData[] = [];
  public linkedUnearnedIncomesList: IMoneyhubTransactionData[] = [];

  private _onRefreshDone = {
    next: () => {
      this._loading = false;
    },
    error: (err) => {
      this._loading = false;
      this.notifier.notify(Constants.ERROR, 'Failed to refresh');
    },
  };

  private moneyhubUserId: string;
  private incomeStatementFromDate: string;

  constructor(
    private dragulaService: DragulaService,
    private configService: AppConfigService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private titleService: Title,
    private t: TranslateService,
    private notifier: NotifierService,
    private financialAssetsService: FinancialAssetsService,
    private personsService: PersonsService,
    private rentExpenseService: RentExpenseService,
    private propertyService: PropertyService,
    private earnedIncomeService: EarnedIncomeService,
    private unearnedIncomeService: UnearnedIncomeService,
    private rentIncomeService: RentIncomeService,
    private livingExpenseService: LivingExpenseService,
    private wizardService: WizardService,
    private moneyhubService: MoneyhubService,
    private featureService: FeatureService,
    private userProperty: UserPropertyService,
    private disabilityInsuranceService: DisabilityInsuranceService
  ) {
    this.dragulaService.createGroup('INCOME', {
      direction: 'horizontal',
      copy: () => true,
      moves: (el) => !el.parentElement.classList.value.includes('disabled'),
      accepts: (el, target) => {
        return (
          target === document.getElementById('dropzone_income_1') ||
          target === document.getElementById('dropzone_income_2')
        );
      },
    });
    this.dragulaService.createGroup('EXPENSE', {
      direction: 'horizontal',
      copy: () => true,
      moves: (el) => !el.parentElement.classList.value.includes('disabled'),
      accepts: (el, target) => {
        return (
          target === document.getElementById('dropzone_expense_1') ||
          target === document.getElementById('dropzone_expense_2')
        );
      },
    });
    this._subs.push(
      this.dragulaService
        .drop('INCOME')
        .subscribe((el) => this.handleIncomeDrop(el.source))
    );
    this._subs.push(
      this.dragulaService
        .drop('EXPENSE')
        .subscribe((el) => this.handleExpenseDrop(el.source))
    );
    this._subs.push(
      this.dragulaService
        .drag('INCOME')
        .subscribe(() => (this._scrollableIncome = false))
    );
    this._subs.push(
      this.dragulaService
        .drag('EXPENSE')
        .subscribe(() => (this._scrollableExpense = false))
    );

    this._subs.push(
      this.dragulaService
        .dragend('INCOME')
        .subscribe(() => (this._scrollableIncome = true))
    );
    this._subs.push(
      this.dragulaService
        .dragend('EXPENSE')
        .subscribe(() => (this._scrollableExpense = true))
    );
    this._subs.push(
      this.dragulaService.drop('INCOME').subscribe((d) => {
        this._scrollableIncome = true;
      })
    );
    this._subs.push(
      this.dragulaService.drop('EXPENSE').subscribe((d) => {
        this._scrollableExpense = true;
      })
    );

    this._primaryEarnedIncomes = new Array<EarnedIncome>();
    this._partnerEarnedIncomes = new Array<EarnedIncome>();
    this._unearnedIncomes = new Array<UnearnedIncome>();
    this._rentIncomes = new Array<RentIncome>();
    this._rentExpenses = new Array<RentExpense>();
    this._livingExpenses = new Array<LivingExpense>();
    this._investmentProperties = new Array<InvestmentProperty>();
    this._linkedExpenses = new Array<LivingExpense>();
    this._linkedRentExpenses = new Array<RentExpense>();
    this._linkedIncomes = new Array<EarnedIncome>();
    this._linkedUnearnedIncomes = new Array<UnearnedIncome>();
    window.addEventListener(
      'touchmove',
      (e) =>
        (!this._scrollableIncome || !this._scrollableExpense) &&
        e.preventDefault(),
      { passive: false }
    );
  }

  get incomeDrag() {
    return !this._scrollableIncome;
  }

  get expenseDrag() {
    return !this._scrollableExpense;
  }

  getDraggedElementText(el: Element) {
    return el.childNodes[1].textContent.trim();
  }

  handleIncomeDrop(el: Element) {
    const inDropzone =
      document.getElementById('dropzone_income_2').childNodes.length === 3 ||
      document.getElementById('dropzone_income_1').childNodes.length === 2;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);
      switch (nodeText) {
        case 'My income':
          this.createEarnedIncome(this.primary.id);
          break;
        case "Partner's income":
          this.createEarnedIncome(this.partner.id);
          break;
        case 'Other income':
          this.createUnearnedIncome();
          break;
        case 'Rent':
          this.createRentIncome();
          break;
        default:
          break;
      }
    }

    //empty drop zones
    document.getElementById('dropzone_income_2').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
    document.getElementById('dropzone_income_1').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
  }

  handleExpenseDrop(el: Element) {
    const inDropzone =
      document.getElementById('dropzone_expense_2').childNodes.length === 3 ||
      document.getElementById('dropzone_expense_1').childNodes.length === 2;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);

      switch (nodeText) {
        case 'Basic expenses':
          this.createLivingExpense(1);
          break;
        case 'Lifestyle expenses':
          this.createLivingExpense(0);
          break;
        case 'Rent':
          this.createRentExpense();
          break;
        default:
          break;
      }
    }

    //empty drop zones
    document.getElementById('dropzone_expense_2').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
    document.getElementById('dropzone_expense_1').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
  }

  async ngOnInit() {
    this._locale = this.configService.getConfig().locale;
    this.titleService.setTitle(this.t.instant('Onboarding | Income'));
    this._loading = true;
    this._navigation = (this.route.data as any).value.navigation;
    this.onResize(null);
    this.refreshEntities();
    const user = MoneyhubUser.get();
    if (user) {
      this.moneyhubUserId = user.userId;
      const createdDate = new Date(user.createdAt);
      const fromDate = new Date(
        createdDate.setFullYear(createdDate.getFullYear() - 1)
      );
      this.incomeStatementFromDate = fromDate.toISOString().split('T')[0];
    }
    this._loading = false;
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
    this._subs.forEach((s) => s.unsubscribe());
    this.dragulaService.destroy('INCOME');
    this.dragulaService.destroy('EXPENSE');
  }

  onResize(event) {
    // this.breakpoint = (event.target.innerWidth <= 1100) ? 1 : 2;
    this.breakpointIncomes =
      window.innerWidth < 599
        ? this.totalIncomes
        : window.innerWidth <= 1100
          ? 1
          : 2;
    this.breakpointExpenses =
      window.innerWidth < 599
        ? this.totalExpenses
        : window.innerWidth <= 1100
          ? 1
          : 2;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  getGridWidth(total) {
    return window.innerWidth < 599 ? total * 90 + 'px' : 'auto';
  }

  async getLinkedData() {
    try {
      this._loading = true;
      const statement = await this.moneyhubService
        .getIncomeStatement(this.incomeStatementFromDate)
        .toPromise();
      if (!statement) {
        return;
      }
      const earnedIncome = statement.income;
      const otherIncome = statement.otherIncome;
      const basicExpense = statement.basicExpense;
      const lifestyleExpense = statement.lifestyleExpense;
      const rentExpense = statement.rentExpense;
      if (
        !earnedIncome &&
        !otherIncome &&
        !basicExpense &&
        !lifestyleExpense &&
        !rentExpense
      ) {
        return;
      }
      const localConfigCurrency =
        Constants.LOCALE_CONFIG[this._locale].currency;
      if (earnedIncome && earnedIncome.currency === localConfigCurrency) {
        this.linkedEarnedIncomesList = [
          ...this.linkedEarnedIncomesList,
          ...earnedIncome.transactions,
        ];
        let incomeEarned: EarnedIncome;
        // check if we already have an earned income
        incomeEarned = this._primaryEarnedIncomes.find(
          (e) => e.description === Constants.SOURCE_MONEYHUB + ':' + 'earned'
        );
        if (!!incomeEarned) {
          this._incomeTmp = { ...incomeEarned };
          incomeEarned.amount = earnedIncome.amount;
          incomeEarned.isFromLinkedAccounts = true;
        } else {
          incomeEarned = new EarnedIncomeBuilder()
            .name(`My income ${this._primaryEarnedIncomes.length + 1}`)
            .description(Constants.SOURCE_MONEYHUB + ':' + 'earned')
            .currency(Constants.LOCALE_CONFIG[this._locale].currency)
            .amount(earnedIncome.amount)
            .startDate(DateUtils.thisYear())
            .endDate(DateUtils.thisYear())
            .endsOn(DateRangeType.ON_RETIREMENT)
            .frequency(Frequency.MONTHLY)
            .growthRate(BaseGrowthRate.CALCULATED)
            .linkedAccount(true)
            .build();
          // income.source = Constants.SOURCE_MONEYHUB;
          this._linkedIncomes.push(incomeEarned);
        }
        this.showInputForId = null;
      }

      if (otherIncome && otherIncome.currency === localConfigCurrency) {
        this.linkedUnearnedIncomesList = [
          ...this.linkedUnearnedIncomesList,
          ...otherIncome.transactions,
        ];
        let incomeUnearned: UnearnedIncome;
        // check if we already have an earned income
        incomeUnearned = this._unearnedIncomes.find(
          (e) => e.description === Constants.SOURCE_MONEYHUB + ':' + 'unearned'
        );
        if (!!incomeUnearned) {
          this._incomeUnearnedTmp = { ...incomeUnearned };
          incomeUnearned.amount = otherIncome.amount;
          incomeUnearned.isFromLinkedAccounts = true;
        } else {
          incomeUnearned = new UnearnedIncomeBuilder()
            .name(`My other income ${this._unearnedIncomes.length + 1}`)
            .description(Constants.SOURCE_MONEYHUB + ':' + 'unearned')
            .currency(Constants.LOCALE_CONFIG[this._locale].currency)
            .amount(otherIncome.amount)
            .startDate(DateUtils.thisYear())
            .endDate(DateUtils.thisYear())
            .endsOn(DateRangeType.ON_RETIREMENT)
            .frequency(Frequency.MONTHLY)
            .growthRate(BaseGrowthRate.CALCULATED)
            .linkedAccount(true)
            .taxable(true)
            .build();
          this._linkedUnearnedIncomes.push(incomeUnearned);
        }
        this.showInputForId = null;
      }

      if (
        lifestyleExpense &&
        lifestyleExpense.currency === localConfigCurrency
      ) {
        let expenseLifeStyle: LivingExpense;
        // check if we already have a living expense
        expenseLifeStyle = this._livingExpenses.find(
          (e) => e.description === Constants.SOURCE_MONEYHUB + ':' + 'lifestyle'
        );
        if (!!expenseLifeStyle) {
          this._expenseTmp = { ...expenseLifeStyle };
          expenseLifeStyle.amount = lifestyleExpense.amount;
          expenseLifeStyle.isFromLinkedAccounts = true;
          expenseLifeStyle.linkedLivingExpenses = [
            ...lifestyleExpense.transactions,
          ];
        } else {
          // otherwise create a new one
          expenseLifeStyle = new LivingExpenseBuilder()
            .currency(Constants.LOCALE_CONFIG[this._locale].currency)
            .description(Constants.SOURCE_MONEYHUB + ':' + 'lifestyle')
            .amount(lifestyleExpense.amount)
            .startDate(DateUtils.thisYear())
            .survivorAdjustmentPercentage(
              Constants.DEFAULT_SURVIVOR_ADJUSTMENT_PERCENTAGE
            )
            .name('Lifestyle Expense')
            .endDate(DateUtils.thisYear())
            .endsOn(DateRangeType.ON_DEATH)
            .frequency(Frequency.MONTHLY)
            .nonDiscretionaryPercentage(0)
            .linkedAccount(true)
            .linkedLivingExpenses([...lifestyleExpense.transactions])
            .build();
          // expense.source = Constants.SOURCE_MONEYHUB;
          this._linkedExpenses.push(expenseLifeStyle);
        }
        this.showInputForId = null;
      }

      if (basicExpense && basicExpense.currency === localConfigCurrency) {
        let expenseBasic: LivingExpense;
        // check if we already have a living expense
        expenseBasic = this._livingExpenses.find(
          (e) => e.description === Constants.SOURCE_MONEYHUB + ':' + 'basic'
        );
        if (!!expenseBasic) {
          this._basicExpenseTmp = { ...expenseBasic };
          expenseBasic.amount = basicExpense.amount;
          expenseBasic.isFromLinkedAccounts = true;
          expenseBasic.linkedLivingExpenses = [...basicExpense.transactions];
        } else {
          // otherwise create a new one
          expenseBasic = new LivingExpenseBuilder()
            .currency(Constants.LOCALE_CONFIG[this._locale].currency)
            .description(Constants.SOURCE_MONEYHUB + ':' + 'basic')
            .amount(basicExpense.amount)
            .startDate(DateUtils.thisYear())
            .survivorAdjustmentPercentage(
              Constants.DEFAULT_SURVIVOR_ADJUSTMENT_PERCENTAGE
            )
            .name('Basic Expense')
            .endDate(DateUtils.thisYear())
            .endsOn(DateRangeType.ON_DEATH)
            .frequency(Frequency.MONTHLY)
            .nonDiscretionaryPercentage(1)
            .linkedAccount(true)
            .linkedLivingExpenses([...basicExpense.transactions])
            .build();
          // expense.source = Constants.SOURCE_MONEYHUB;
          this._linkedExpenses.push(expenseBasic);
        }
        this.showInputForId = null;
      }

      if (rentExpense && rentExpense.currency === localConfigCurrency) {
        let expenseRent: RentExpense;
        // check if we already have a rent expense
        expenseRent = this._rentExpenses.find(
          (e) => e.description === Constants.SOURCE_MONEYHUB + ':' + 'rent'
        );
        if (!!expenseRent) {
          this._rentExpenseTmp = { ...expenseRent };
          expenseRent.amount = rentExpense.amount;
          expenseRent.isFromLinkedAccounts = true;
          expenseRent.linkedRentExpenses = [...rentExpense.transactions];
        } else {
          // otherwise create a new one
          expenseRent = new RentExpenseBuilder()
            .currency(Constants.LOCALE_CONFIG[this._locale].currency)
            .description(Constants.SOURCE_MONEYHUB + ':' + 'rent')
            .amount(rentExpense.amount)
            .startDate(DateUtils.thisYear())
            .survivorAdjustmentPercentage(
              Constants.DEFAULT_SURVIVOR_ADJUSTMENT_PERCENTAGE
            )
            .name(
              Constants.DEFAULT_RENT_EXPENSE_NAME +
                ' ' +
                `${this._linkedRentExpenses.length + 1}`
            )
            .endDate(DateUtils.thisYear())
            .endsOn(DateRangeType.ON_DEATH)
            .frequency(Frequency.MONTHLY)
            .nonDiscretionaryPercentage(1)
            .linkedAccount(true)
            .linkedRentExpenses([...rentExpense.transactions])
            .build();
          this._linkedRentExpenses.push(expenseRent);
        }
        this.showInputForId = null;
      }
    } catch (ex) {
      this._loading = false;
      this.notifier.notify(Constants.ERROR, 'Could not retrieve linked data');
    } finally {
      this._loading = false;
    }
  }

  refreshPrimaryEarnedIncomes = () =>
    this.$refreshPrimaryEarnedIncomes().subscribe(this._onRefreshDone);

  refreshPartnerEarnedIncomes = () =>
    this.$refreshPartnerEarnedIncomes().subscribe(this._onRefreshDone);

  refreshUnearnedIncomes = () =>
    this.$refreshUnearnedIncomes().subscribe(this._onRefreshDone);

  refreshRentIncomes = () =>
    this.$refreshRentIncomes().subscribe(this._onRefreshDone);

  refreshLivingExpenses = () =>
    this.$refreshLivingExpenses().subscribe(this._onRefreshDone);

  refreshRentExpenses = () =>
    this.$refreshRentExpenses().subscribe(this._onRefreshDone);

  deletePrimaryAssociatedInsurance(income: EarnedIncome) {
    if (!this._primaryDisabilityInsurance) return;
    this._primaryDisabilityInsurance.map((d) => {
      if (d.description === income.id) {
        this.disabilityInsuranceService
          .deleteForPerson(this._scenarioId, this._primary.id, d.id)
          .subscribe();
      }
    });
  }

  deletePartnerAssociatedInsurance(income: EarnedIncome) {
    if (!this._partnerDisabilityInsurance) return;
    this._partnerDisabilityInsurance.map((d) => {
      if (d.description === income.id) {
        this.disabilityInsuranceService
          .deleteForPerson(this._scenarioId, this._partner.id, d.id)
          .subscribe();
      }
    });
  }

  $refreshPrimaryEarnedIncomes = () =>
    this.earnedIncomeService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._primaryEarnedIncomes = r.content)));

  $refreshPartnerEarnedIncomes = () =>
    this.earnedIncomeService
      .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._partnerEarnedIncomes = r.content)));

  $refreshUnearnedIncomes = () =>
    this.unearnedIncomeService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._unearnedIncomes = r.content)));

  $refreshRentIncomes = () =>
    this.propertyService
      .queryInvestmentProperties(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        flatMap((r) => {
          this._investmentProperties = r.content;
          return this.rentIncomeService.queryForPerson(
            this._scenarioId,
            this._primary.id,
            Constants.PAGE_ALL
          );
        })
      )
      .pipe(
        map((r) => {
          this._rentIncomes = r.content;
          this._rentalIncomesPerAnnum = this._rentIncomes.map((rentIncome) =>
            Math.round(
              rentIncome.annualNetRentalYieldPercentage *
                this._investmentProperties.find(
                  (i) => i.id === rentIncome.propertyAssetId
                ).value
            )
          );
        })
      );

  $refreshLivingExpenses = () =>
    this.livingExpenseService
      .query(this._scenarioId, Constants.PAGE_ALL)
      .pipe(map((r) => (this._livingExpenses = r.content)));

  $refreshRentExpenses = () =>
    this.rentExpenseService
      .query(this._scenarioId, Constants.PAGE_ALL)
      .pipe(map((r) => (this._rentExpenses = r.content)));

  $refreshPrimaryDisabilityInsurances = () =>
    this.disabilityInsuranceService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._primaryDisabilityInsurance = r.content;
        })
      );

  $refreshPartnerDisabilityInsurances = () =>
    this.disabilityInsuranceService
      .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._partnerDisabilityInsurance = r.content;
        })
      );

  refreshEntities() {
    this._loading = true;
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];

    forkJoin([
      this.personsService.getPrimary(this._scenarioId),
      this.personsService.getPartner(this._scenarioId),
    ])
      .pipe(
        flatMap((resp) => {
          this._primary = resp[0];
          this._partner = resp[1];
          return forkJoin([
            this.$refreshPrimaryEarnedIncomes(),
            this.$refreshRentIncomes(),
            this._partner ? this.$refreshPartnerEarnedIncomes() : of(null),
            this.$refreshUnearnedIncomes(),
            this.$refreshRentExpenses(),
            this.$refreshLivingExpenses(),
            this.$refreshPrimaryDisabilityInsurances(),
            this._partner
              ? this.$refreshPartnerDisabilityInsurances()
              : of(null),
          ]);
        }),
        flatMap(() => this.wizardService.listenToNotification())
      )
      .subscribe(
        async (notifications) => {
          if (notifications[this.route.snapshot.url[0].path]) {
            this.wizardService.hideNotificationForPath(
              this.route.snapshot.url[0].path
            );
            await this.getLinkedData();
          }
          this._loading = false;
        },
        (err) => {
          this._loading = false;
          console.log(err);
          this.notifier.notify(Constants.ERROR, 'Failed to refresh');
        }
      );
  }

  // earned
  createEarnedIncome = (personId: string) => {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_INCOME,
      AnalyticsLabel.EARNED_INCOME
    );
    const income: EarnedIncome = new EarnedIncomeBuilder()
      .name(
        this.primary.id === personId
          ? `My income ${this._primaryEarnedIncomes.length + 1}`
          : `Partner's income ${this._partnerEarnedIncomes.length + 1}`
      )
      .currency(Constants.LOCALE_CONFIG[this._locale].currency)
      .amount(Constants.DEFAULT_EARNED_INCOME_AMOUNT)
      .startDate(DateUtils.thisYear())
      .endDate(DateUtils.thisYear())
      .endsOn(DateRangeType.ON_RETIREMENT)
      .frequency(Frequency.ANNUALLY)
      .growthRate(BaseGrowthRate.CALCULATED)
      .build();

    this.earnedIncomeService
      .createForPerson(this._scenarioId, personId, income)
      .subscribe(
        () =>
          personId === this._primary.id
            ? this.refreshPrimaryEarnedIncomes()
            : this.refreshPartnerEarnedIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Earned income created')
      );
  };

  updateEarnedIncome = (income: EarnedIncome, personId: string) => {
    if (!income.id || income.amount === null) {
      return;
    }
    this._loading = true;
    this.earnedIncomeService
      .updateForPerson(this._scenarioId, personId, income)
      .subscribe(
        () =>
          personId === this._primary.id
            ? this.refreshPrimaryEarnedIncomes()
            : this.refreshPartnerEarnedIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Earned income updated')
      );
  };

  deleteEarnedIncome = (income: EarnedIncome, personId: string) => {
    this._loading = true;
    this.earnedIncomeService
      .deleteForPerson(this._scenarioId, personId, income.id)
      .subscribe(
        () => {
          if (personId === this._primary.id) {
            this.deletePrimaryAssociatedInsurance(income);
            this.refreshPrimaryEarnedIncomes();
          } else {
            this.deletePartnerAssociatedInsurance(income);
            this.refreshPartnerEarnedIncomes();
          }
        },
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Earned income deleted')
      );
  };

  // unearned
  createUnearnedIncome = () => {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_INCOME,
      AnalyticsLabel.UNEARNED_INCOME
    );
    const income: UnearnedIncome = new UnearnedIncomeBuilder()
      .type(UnearnedIncomeType.OTHER)
      .currency(Constants.LOCALE_CONFIG[this._locale].currency)
      .amount(Constants.DEFAULT_EARNED_INCOME_AMOUNT)
      .taxable(true)
      .name(Constants.DEFAULT_UNEARNED_INCOME_NAME)
      .startDate(DateUtils.thisYear())
      .endDate(DateUtils.thisYear())
      .endsOn(DateRangeType.ON_RETIREMENT)
      .frequency(Frequency.ANNUALLY)
      .growthRate(BaseGrowthRate.CALCULATED)
      .build();

    this.unearnedIncomeService
      .createForPerson(this._scenarioId, this._primary.id, income)
      .subscribe(
        () => this.refreshUnearnedIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Unearned income created')
      );
  };

  updateUnearnedIncome = (income: UnearnedIncome) => {
    if (!income || income.amount === null) {
      return;
    }
    this._loading = true;
    this.unearnedIncomeService
      .updateForPerson(this._scenarioId, this._primary.id, income)
      .subscribe(
        () => this.refreshUnearnedIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Unearned income updated')
      );
  };

  deleteUnearnedIncome = (income: UnearnedIncome) => {
    this._loading = true;
    this.unearnedIncomeService
      .deleteForPerson(this._scenarioId, this._primary.id, income.id)
      .subscribe(
        () => this.refreshUnearnedIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Unearned income deleted')
      );
  };

  // rent
  createRentIncome = () => {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_INCOME,
      AnalyticsLabel.RENT_INCOME
    );
    const income: RentIncome = new RentIncomeBuilder()
      .propertyAssetId(this._investmentProperties[0].id)
      .currency(Constants.LOCALE_CONFIG[this._locale].currency)
      .name(Constants.DEFAULT_RENT_INCOME_NAME)
      .startDate(DateUtils.thisYear())
      .endDate(DateUtils.thisYear())
      .endsOn(DateRangeType.ON_DEATH)
      .annualNetRentalYieldPercentage(
        Constants.DEFAULT_RENT_INCOME_ANNUAL_YIELD_PERCENTAGE
      )
      .build();

    this.rentIncomeService
      .createForPerson(this._scenarioId, this._primary.id, income)
      .subscribe(
        () => this.refreshRentIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Rent income created')
      );
  };

  updateRentIncome = (el: NgModel, income: RentIncome) => {
    if (!income || el.value === null) {
      return;
    }
    this._loading = true;
    const rentValue = el.value;
    const propValue = this._investmentProperties
      .filter((p) => p.id === income.propertyAssetId)
      .map((p) => p.value)[0];
    income.annualNetRentalYieldPercentage =
      Math.round((parseFloat(rentValue) * 12 * 100000) / propValue) / 100000;
    if (income.annualNetRentalYieldPercentage > 1) {
      el.control.markAsTouched();
      el.control.setErrors({ max: true });
      this._loading = false;
      return;
    }
    this.rentIncomeService
      .updateForPerson(this._scenarioId, this._primary.id, income)
      .subscribe(
        () => this.refreshRentIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Rent income updated')
      );
  };

  deleteRentIncome = (income: RentIncome) => {
    this._loading = true;
    this.rentIncomeService
      .deleteForPerson(this._scenarioId, this._primary.id, income.id)
      .subscribe(
        () => this.refreshRentIncomes(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Rent income deleted')
      );
  };

  // living expenses
  createLivingExpense = (nonDiscretionaryPercentage: number) => {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_EXPENSE,
      AnalyticsLabel.LIVING_EXPENSE
    );
    const expense: LivingExpense = new LivingExpenseBuilder()
      .currency(Constants.LOCALE_CONFIG[this._locale].currency)
      .amount(Constants.DEFAULT_LIVING_EXPENSE_MONTHLY_AMOUNT)
      .startDate(DateUtils.thisYear())
      .survivorAdjustmentPercentage(
        Constants.DEFAULT_SURVIVOR_ADJUSTMENT_PERCENTAGE
      )
      .name(Constants.DEFAULT_EXPENSE_NAME)
      .endDate(DateUtils.thisYear())
      .endsOn(DateRangeType.ON_DEATH)
      .frequency(Frequency.MONTHLY)
      .nonDiscretionaryPercentage(nonDiscretionaryPercentage)
      .build();

    this.livingExpenseService.create(this._scenarioId, expense).subscribe(
      () => this.refreshLivingExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Living expense created')
    );
  };

  updateLivingExpense = (expense: LivingExpense) => {
    if (!expense.id || expense.amount === null) {
      return;
    }
    this._loading = true;
    this.livingExpenseService.update(this._scenarioId, expense).subscribe(
      () => this.refreshLivingExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Living expense updated')
    );
  };

  deleteLivingExpense = (expense: LivingExpense) => {
    this._loading = true;
    this.livingExpenseService.delete(this._scenarioId, expense.id).subscribe(
      () => this.refreshLivingExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Living expense deleted')
    );
  };

  createRentExpense = () => {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_EXPENSE,
      AnalyticsLabel.RENT_EXPENSE
    );
    const expense: RentExpense = new RentExpenseBuilder()
      .name(Constants.DEFAULT_RENT_EXPENSE_NAME)
      .amount(Constants.DEFAULT_RENT_EXPENSE_AMOUNT)
      .currency(Constants.LOCALE_CONFIG[this._locale].currency)
      .endDate(DateUtils.thisYear())
      .endsOn(DateRangeType.ON_DEATH)
      .frequency(Frequency.MONTHLY)
      .growthRate(BaseGrowthRate.CALCULATED)
      .startDate(DateUtils.thisYear())
      .startsOn(DateRangeType.USER_DEFINED)
      .build();
    this.rentExpenseService.create(this._scenarioId, expense).subscribe(
      () => this.refreshRentExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Rent expense created')
    );
  };

  updateRentExpense = (expense: RentExpense) => {
    if (!expense.id || expense.amount === null) {
      return;
    }
    this._loading = true;
    this.rentExpenseService.update(this._scenarioId, expense).subscribe(
      () => this.refreshRentExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Rent expense updated')
    );
  };

  deleteRentExpense = (expense: RentExpense) => {
    this._loading = true;
    this.rentExpenseService.delete(this._scenarioId, expense.id).subscribe(
      () => this.refreshRentExpenses(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Rent expense deleted')
    );
  };

  get featureMoneyHubAccounts() {
    return AppFeatureType.MoneyHubAccounts;
  }

  get navigation() {
    return this._navigation;
  }

  get primary(): Person {
    return this._primary;
  }

  get partner(): Person {
    return this._partner;
  }

  get primaryEarnedIncomes(): EarnedIncome[] {
    return [...this._primaryEarnedIncomes, ...this._linkedIncomes];
  }

  get partnerEarnedIncomes(): EarnedIncome[] {
    return this._partnerEarnedIncomes;
  }

  get unearnedIncomes(): UnearnedIncome[] {
    return [...this._unearnedIncomes, ...this._linkedUnearnedIncomes];
  }

  get rentIncomes(): RentIncome[] {
    return this._rentIncomes;
  }

  get rentExpenses(): RentExpense[] {
    return [...this._rentExpenses, ...this._linkedRentExpenses];
  }

  get investmentProperties(): Property[] {
    return this._investmentProperties;
  }

  get livingExpenses(): LivingExpense[] {
    return [...this._livingExpenses, ...this._linkedExpenses];
  }

  get basicExpenses(): LivingExpense[] {
    return this._livingExpenses.filter(
      (e) => e.nonDiscretionaryPercentage === 1
    );
  }

  get lifestyleExpenses(): LivingExpense[] {
    return this._livingExpenses.filter((e) => e.nonDiscretionaryPercentage < 1);
  }

  get hasLinkedData() {
    return (
      this._linkedExpenses.length > 0 ||
      this._linkedIncomes.length > 0 ||
      this._linkedUnearnedIncomes.length > 0
    );
  }

  get hasConnectedAccounts() {
    return (
      !!localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_USER) &&
      !!localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS)
    );
  }

  dragStart(category: string, cb: Function, param?: any) {
    this._dragCategory = category;
    this._onDragEnd = cb;
    this._dragParam = param;
    this._draggingCategory = category;
  }

  dragEnd() {
    this._draggingCategory = null;
  }

  onDrop(category: string) {
    if (category === this._dragCategory) {
      this._onDragEnd(this._dragParam);
    }
  }

  getFrequency(obj: EarnedIncome | UnearnedIncome | LivingExpense) {
    switch (obj.frequency) {
      case Frequency.ANNUALLY:
        return '(per annum)';
      case Frequency.MONTHLY:
        return '(per month)';
      case Frequency.WEEKLY:
        return '(per week)';
      case Frequency.ONE_OFF:
        return '(one-off)';
      case Frequency.UNSPECIFIED:
      default:
        return '';
    }
  }

  get rentalIncomesPerAnnum() {
    return this._rentalIncomesPerAnnum;
  }

  get frequencies() {
    return Constants.SELECTABLE_FREQUENCIES;
  }

  getFrequencyLabel(frequency) {
    return Constants.FREQUENCY_LABELS.find((f) => f.id === frequency).label;
  }

  get loading() {
    return this._loading;
  }

  get isMoneyHubEnabled() {
    return this.featureService.hasFeature(AppFeatureType.MoneyHubAccounts);
  }

  getMonthlyRent(income: RentIncome) {
    return this._investmentProperties
      .filter((i) => i.id === income.propertyAssetId)
      .map((p) => p.value)
      .map((val) => (income.annualNetRentalYieldPercentage * val) / 12)
      .map((val) => Math.round(val))[0];
  }

  getPropertyValue(income: RentIncome) {
    return this._investmentProperties.find(
      (i) => i.id === income.propertyAssetId
    ).value;
  }

  getIncomesSumPerAnnum() {
    const accFn = (a, b) => a + b;
    let sum = 0;
    sum += this._primaryEarnedIncomes
      .map((i) => i.amount * this.toAnnualFrequencyMultiplier(i.frequency))
      .reduce(accFn, 0);
    sum += this._partnerEarnedIncomes
      .map((i) => i.amount * this.toAnnualFrequencyMultiplier(i.frequency))
      .reduce(accFn, 0);
    sum += this._unearnedIncomes
      .map((i) => i.amount * this.toAnnualFrequencyMultiplier(i.frequency))
      .reduce(accFn, 0);
    sum += this._rentalIncomesPerAnnum.reduce(accFn, 0);
    return Math.round(sum);
  }

  toAnnualFrequencyMultiplier(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.DAILY:
        return 365;
      case Frequency.WEEKLY:
        return 52;
      case Frequency.MONTHLY:
        return 12;
      case Frequency.QUARTERLY:
        return 4;
      case Frequency.SEMI_ANNUALLY:
        return 2;
      case Frequency.ANNUALLY:
      case Frequency.ONE_OFF:
      default:
        return 1;
    }
  }

  getExpensesSumPerAnnum() {
    const accFn = (a, b) => a + b;
    let sum = 0;
    sum += this.basicExpenses
      .map(
        (exp) => exp.amount * this.toAnnualFrequencyMultiplier(exp.frequency)
      )
      .reduce(accFn, 0);
    sum += this.lifestyleExpenses
      .map(
        (exp) => exp.amount * this.toAnnualFrequencyMultiplier(exp.frequency)
      )
      .reduce(accFn, 0);
    sum += this.rentExpenses
      .map(
        (exp) => exp.amount * this.toAnnualFrequencyMultiplier(exp.frequency)
      )
      .reduce(accFn, 0);
    return sum;
  }

  get currency() {
    return Constants.LOCALE_CONFIG[this._locale].currencySymbol;
  }

  removeLinkedLivingExpense(expense: LivingExpense) {
    if (expense.id) {
      this._livingExpenses = this._livingExpenses.map((e) => {
        if (e.id === expense.id) {
          if (e.id === this._expenseTmp.id) {
            return this._expenseTmp;
          }
          if (e.id === this._basicExpenseTmp.id) {
            return this._basicExpenseTmp;
          }
        }
        return e;
      });
    } else {
      this._linkedExpenses = this._linkedExpenses.filter(
        (e) => e.description !== expense.description
      );
    }
  }

  saveLinkedLivingExpense(expense: LivingExpense) {
    this._loading = true;
    iif(
      () => !!expense.id,
      this.livingExpenseService.update(this._scenarioId, expense),
      this.livingExpenseService.create(this._scenarioId, expense)
    ).subscribe(
      () => {
        this.refreshLivingExpenses();
        this.removeLinkedLivingExpense(expense);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Living expense ${expense.id ? 'updated' : 'created'}`
        )
    );
  }

  removeLinkedRentExpense(expense: RentExpense) {
    if (expense.id) {
      this._rentExpenses = this._rentExpenses.map((e) =>
        e.id === this._rentExpenseTmp.id ? this._rentExpenseTmp : e
      );
    } else {
      this._linkedRentExpenses = this._linkedRentExpenses.filter(
        (e) => e.description !== expense.description
      );
    }
  }

  saveLinkedRentExpense(expense: RentExpense) {
    this._loading = true;
    iif(
      () => !!expense.id,
      this.rentExpenseService.update(this._scenarioId, expense),
      this.rentExpenseService.create(this._scenarioId, expense)
    ).subscribe(
      () => {
        this.refreshRentExpenses();
        this.removeLinkedRentExpense(expense);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Living expense ${expense.id ? 'updated' : 'created'}`
        )
    );
  }

  removeLinkedEarnedIncome(income: EarnedIncome) {
    if (income.id) {
      this._primaryEarnedIncomes = this._primaryEarnedIncomes.map((e) =>
        e.id === this._incomeTmp.id ? this._incomeTmp : e
      );
    } else {
      this._linkedIncomes = this._linkedIncomes.filter(
        (i) => i.id !== income.id
      );
    }
  }

  saveLinkedEarnedIncome(income: EarnedIncome) {
    this._loading = true;
    iif(
      () => !!income.id,
      this.earnedIncomeService.updateForPerson(
        this._scenarioId,
        this.primary.id,
        income
      ),
      this.earnedIncomeService.createForPerson(
        this._scenarioId,
        this.primary.id,
        income
      )
    ).subscribe(
      () => {
        this.refreshPrimaryEarnedIncomes();
        this.removeLinkedEarnedIncome(income);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Earned income ${income.id ? 'updated' : 'created'}`
        )
    );
  }

  removeLinkedUnearnedIncome(income: UnearnedIncome) {
    if (income.id) {
      this._unearnedIncomes = this._unearnedIncomes.map((e) =>
        e.id === this._incomeUnearnedTmp.id ? this._incomeUnearnedTmp : e
      );
    } else {
      this._linkedUnearnedIncomes = this._linkedUnearnedIncomes.filter(
        (i) => i.description !== income.description
      );
    }
  }

  saveLinkedUnearnedIncome(income: UnearnedIncome) {
    this._loading = true;
    iif(
      () => !!income.id,
      this.unearnedIncomeService.updateForPerson(
        this._scenarioId,
        this.primary.id,
        income
      ),
      this.unearnedIncomeService.createForPerson(
        this._scenarioId,
        this.primary.id,
        income
      )
    ).subscribe(
      () => {
        this.refreshUnearnedIncomes();
        this.removeLinkedUnearnedIncome(income);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Earned income ${income.id ? 'updated' : 'created'}`
        )
    );
  }
}
