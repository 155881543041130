// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.coin-container {
  touch-action: none;
  -ms-touch-action: none;
  visibility: visible !important;
  transition: all 0.2s ease-out;
}
.coin-container.disabled {
  opacity: 0.3;
}

.hide {
  display: none;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}

#dropzone_protections_2 .coin,
#dropzone_protections_1 .coin {
  display: none !important;
}

.disabled .coin {
  cursor: default;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select {
  box-sizing: border-box;
  max-width: 100%;
}

.mat-icon {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/protection/protection.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;;EAEE,wBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAMA,6HAAA;AACA;EACE,sBAAA;EACA,eAAA;AAHF;;AAMA;EACE,eAAA;AAHF","sourcesContent":[".mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.coin-container {\n  touch-action: none;\n  -ms-touch-action: none;\n  visibility: visible !important;\n  transition: all 0.2s ease-out;\n  &.disabled {\n    opacity: 0.3;\n  }\n}\n\n.hide {\n  display: none;\n}\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n\n  &.visible {\n    visibility: visible;\n  }\n}\n\n#dropzone_protections_2 .coin,\n#dropzone_protections_1 .coin {\n  display: none !important;\n}\n\n.disabled .coin {\n  cursor: default;\n}\n\n.checkbox-joint {\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */\nmat-select {\n  box-sizing: border-box;\n  max-width: 100%;\n}\n\n.mat-icon {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
