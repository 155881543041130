import { AppFeatureType } from './feature';
import { MoneyhubAuthGuard } from './guards/moneyhub.auth.guard';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from './app.constants';
import { RealmUserService, ScenarioService, SharedService } from './services';
import { Router } from '@angular/router';
import { UserPropertyService } from './services/user.property.service';
import { FeatureService } from './feature/feature.service';
import { MoneyhubService } from './services/moneyhub.service';
import { iif, map, mergeMap, of, Subscription } from 'rxjs';
import { PreferenceService } from './services/preference.service';
import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { NotifierService } from 'angular-notifier';
import { DEFAULT_MONEYHUB_USER } from './model';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-app';
  loading = false;

  returnUrl: string;
  tfaEnabled = false;
  scenarioId: string;
  loginFormError: string;
  socialLoginError: string;
  skipLogin: boolean;
  featureLogin = AppFeatureType.Login;
  featureGuestLogin = AppFeatureType.GuestLogin;
  featureRegister = AppFeatureType.Register;
  featureSampleAppWarn = AppFeatureType.SampleAppWarning;
  featureSocialLogin = AppFeatureType.SocialLogin;

  private _subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    public mhguard: MoneyhubAuthGuard,
    private scenarioService: ScenarioService,
    private router: Router,
    private userPropertyService: UserPropertyService,
    private featureService: FeatureService,
    private moneyhubService: MoneyhubService,
    private preferenceService: PreferenceService,
    private oAuthService: OAuthService,
    private notifier: NotifierService,
    private sharedService: SharedService,
    private realmUserService: RealmUserService
  ) {
    console.log(packageJson.name + ' v' + packageJson.version);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
    this.mhguard.loading$.subscribe((data) => {
      this.loading = data;
    });
  }

  ngOnInit() {
    this._subs.push(
      this.sharedService
        .getIsAuthenticated()
        .subscribe(
          (isAuthenticated: boolean) =>
            isAuthenticated && this.handleLoginResponse()
        )
    );
  }

  async handleLoginResponse() {
    const accessToken = this.oAuthService.getAccessToken();
    const decoded: any = jwt_decode(accessToken);
    if (accessToken) {
      this.storeUserData(decoded);
      this._subs.push(
        this.realmUserService.getAccount().subscribe({
          next: (resp) => {
            if (resp.email) {
              localStorage.setItem(
                Constants.LOCAL_STORAGE_USER_EMAIL,
                resp.email
              );
            }
          },
        })
      );
      if (this.featureService.hasFeature(AppFeatureType.MoneyHubAccounts)) {
        try {
          const data = await Promise.all([
            await this.userPropertyService
              .get(Constants.USER_PROPERTY_KEY_MONEYHUB_USER_ID)
              .toPromise(),
            await this.userPropertyService
              .get(Constants.USER_PROPERTY_KEY_MONEYHUB_USER_CREATION_DATE)
              .toPromise(),
          ]);
          const moneyhubUserIdProp = data[0];
          const moneyhubUserCreationDate = data[1];
          if (moneyhubUserIdProp.value && moneyhubUserCreationDate.value) {
            localStorage.setItem(
              Constants.LOCAL_STORAGE_MONEYHUB_USER,
              JSON.stringify({
                ...DEFAULT_MONEYHUB_USER,
                userId: moneyhubUserIdProp.value,
                createdAt: moneyhubUserCreationDate.value,
              })
            );
            const tokenResp = await this.moneyhubService
              .getUserToken()
              .toPromise();
            localStorage.setItem(
              Constants.LOCAL_STORAGE_MONEYHUB_TOKEN,
              tokenResp.access_token
            );
            const connections = await this.moneyhubService
              .getConnectionsWithDetails()
              .toPromise();
            if (connections.length > 0) {
              localStorage.setItem(
                Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS,
                JSON.stringify(connections)
              );
            } else {
              if (
                localStorage.getItem(
                  Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS
                )
              ) {
                localStorage.removeItem(
                  Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS
                );
              }
            }
          }
        } catch (e) {
          /* empty */
        }
      }
      this.loading = false;
    }
  }

  storeUserData(decoded) {
    localStorage.setItem(Constants.LOCAL_STORAGE_USERNAME, decoded['sub']);
    localStorage.setItem(
      Constants.LOCAL_STORAGE_CLIENT_ID,
      '' + decoded[Constants.LOCAL_STORAGE_CLIENT_ID]
    );
    this.createOrSelectScenario()
      .pipe(
        mergeMap((s) => {
          this.scenarioId = s.id;
          return this.preferenceService.get(
            s.id,
            Constants.PREFERENCE_KEY_INITIALIZED
          );
        })
      )
      .subscribe(
        (initialized) => {
          // we capture the url and call router navigate manually, because of initialNavigation: 'disabled' in app-routing
          if (window.location.hash.indexOf('#/connected') > -1) {
            const params = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')))
            this.router.navigate(['connected'], {queryParams: Object.fromEntries(Array.from(params))});
          } else {
            JSON.stringify(initialized) === 'true'
              ? this.navigateToGraph(this.scenarioId)
              : this.navigateToOnboarding(this.scenarioId);
          }
        },
        (err) => this.notifier.notify('error', err)
      );
  }

  createOrSelectScenario() {
    return this.scenarioService
      .query(Constants.PAGE_ALL)
      .pipe(
        map(
          (response) =>
            response.content.filter(
              (s) => s.household.preferences[Constants.PREFERENCE_KEY_CURRENT]
            )[0]
        )
      )
      .pipe(
        mergeMap((current) =>
          iif(
            () => !!current,
            of(current),
            this.scenarioService.create({ name: 'Me Today' }).pipe(
              map((s) => {
                this.preferenceService
                  .update(s.id, Constants.PREFERENCE_KEY_CURRENT, true)
                  .subscribe();
                return s;
              })
            )
          )
        )
      );
  }

  navigateToOnboarding(scenarioId) {
    this.router.navigate(['scenario', scenarioId, 'onboarding', 'family']);
  }

  navigateToGraph(scenarioId) {
    this.router.navigate(['scenario', scenarioId, 'overview', 'graph']);
  }

  ngOnDestroy() {
    // this.authSub && this.authSub.unsubscribe();
    this._subs.forEach((s) => s.unsubscribe());
  }
}
